<template>
  <div
    class="bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-300 border-slate-50 dark:border-slate-800/50 border-l rtl:border-l-0 rtl:border-r contact--panel overflow-y-auto"
  >
    <contact-info
      :contact="contact"
      :channel-type="channelType"
      @toggle-panel="onPanelToggle"
    />
    <draggable
      :list="conversationSidebarItems"
      :disabled="!dragEnabled"
      animation="200"
      class="list-group"
      ghost-class="ghost"
      handle=".drag-handle"
      @start="dragging = true"
      @end="onDragEnd"
    >
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="bg-white dark:bg-gray-800"
        >
          <div
            v-if="element.name === 'conversation_actions'"
            class="conversation--actions"
          >
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_ACTIONS')"
              :is-open="isContactSidebarItemOpen('is_conv_actions_open')"
              @click="
                value => toggleSidebarUIState('is_conv_actions_open', value)
              "
            >
              <conversation-action
                :conversation-id="conversationId"
                :inbox-id="inboxId"
              />
            </accordion-item>
          </div>
            <div  v-if="element.name === 'conversation_pageinfo' && contact.additional_attributes.otherinfo"   class="conversation--pageinfo">
                <accordion-item
                        :title="$t('网站访问信息')"
                        :is-open="isContactSidebarItemOpen('is_conv_pageinfo_open')"
                        @click="
                                value => toggleSidebarUIState('is_conv_pageinfo_open', value)
                                "
                >


                    <div  class="overflow-auto conversation--attribute py-2"  v-if="contact.additional_attributes.otherinfo">
                        <div class="items-center flex justify-between mb-1.5">
                            <span class="text-slate-800 font-medium dark:text-slate-100 text-sm">
                                当前页面名称
                            </span>
                        </div>
                        <div class="break-words">
                            <a target="_blank" :href="contact.additional_attributes.otherinfo.page.url">{{contact.additional_attributes.otherinfo.page.name}}</a>
                        </div>
                    </div>
                    <div  class="overflow-auto conversation--attribute py-2"  v-if="contact.additional_attributes.otherinfo">
                        <div class="items-center flex justify-between mb-1.5">
                            <span class="text-slate-800 font-medium dark:text-slate-100 text-sm">
                                当前页面类型
                            </span>
                        </div>
                        <div class="break-words">
                            {{contact.additional_attributes.otherinfo.page.type}}
                        </div>
                    </div>

                    <div  class="overflow-auto conversation--attribute py-2"  v-if="contact.additional_attributes.otherinfo.product">
                        <div class="product-container">
                            <div class="product-summary-left">
                                <img v-if="contact.additional_attributes.otherinfo.product.image" :src="contact.additional_attributes.otherinfo.product.image" class="product-img" />
                            </div>
                            <div class="product-summary-right">
                                <div class="product-name"><a target="_blank" :href="contact.additional_attributes.otherinfo.product.url">{{contact.additional_attributes.otherinfo.product.name}}</a></div>
                                <div class="product-price"><span class="product-price-span" >{{contact.additional_attributes.otherinfo.website.symbol}}{{contact.additional_attributes.otherinfo.product.price}}</span>
                                    <span class="product-sku" v-if="contact.additional_attributes.otherinfo.product.sku">SKU:{{contact.additional_attributes.otherinfo.product.sku}}</span>
                                </div>

                            </div>
                        </div>
                    </div>

                </accordion-item>
            </div>

            <div  v-if="element.name === 'conversation_cartinfo' && contact.additional_attributes.otherinfo && contact.additional_attributes.otherinfo.cart "   class="conversation--cartinfo">
                <accordion-item
                        :title="$t('网站购物车信息')"
                        :is-open="isContactSidebarItemOpen('is_conv_cartinfo_open')"
                        @click="
                                value => toggleSidebarUIState('is_conv_cartinfo_open', value)
                                "
                >
                    <div  v-for="cart in contact.additional_attributes.otherinfo.cart.items"  :key="cart"   class="custom-cart--row" >
                         <div class="product-container">
                             <div class="product-summary-left">
                             <img v-if="cart.image" :src="cart.image" class="product-img" />
                             </div>
                            <div class="product-summary-right">
                                <div class="product-name"><a target="_blank" :href="cart.url">{{cart.name}}</a></div>
                                <div class="product-price"><span class="product-price-span" >{{contact.additional_attributes.otherinfo.website.symbol}}{{cart.price}}  x {{cart.qty}}</span>
                                    <span class="product-sku" v-if="cart.sku">SKU:{{cart.sku}}</span>
                                </div>
                            </div>
                      </div>

            </div>
                </accordion-item>
            </div>


            <div  v-if="element.name === 'conversation_orderinfo'  && contact.additional_attributes.otherinfo && contact.additional_attributes.otherinfo.user "   class="conversation--orderinfo">
                <accordion-item
                        :title="$t('网站订单信息')"
                        :is-open="isContactSidebarItemOpen('is_conv_orderinfo_open')"
                        @click="
                                value => toggleSidebarUIState('is_conv_orderinfo_open', value)
                                "
                >
                     <div v-if="contact.additional_attributes.otherinfo.user.orders.length==0">还未下单</div>

                     <div  v-for="order in contact.additional_attributes.otherinfo.user.orders"  :key="order.id"   class="custom-cart--row" >

                         <table cellpadding="0" cellspacing="0">
                             <tr>
                                 <td width="140">订单ID:{{order.id}}</td>
                                 <td>订单金额:{{contact.additional_attributes.otherinfo.website.symbol}}{{order.total}}</td>
                             </tr>
                             <tr>
                                 <td>订单状态:{{order.status}}</td>
                                 <td>订单时间:{{order.date}}</td>
                             </tr>
                         </table>
                        <div class="product-container" v-for="cart in order.items"  :key="cart" >
                            <div class="product-summary-left">
                                <img v-if="cart.image" :src="cart.image" class="product-img" />
                            </div>
                            <div class="product-summary-right">
                                <div class="product-name"><a target="_blank" :href="cart.url">{{cart.name}}</a></div>
                                <div class="product-price"><span class="product-price-span" >{{contact.additional_attributes.otherinfo.website.symbol}}{{cart.price}} x {{cart.qty}}</span>
                                <span class="product-sku" v-if="cart.sku">SKU:{{cart.sku}}</span>
                                </div>
                            </div>
                        </div>
                     </div>
                </accordion-item>
            </div>

          <div
            v-else-if="element.name === 'conversation_participants'"
            class="conversation--actions"
          >
            <accordion-item
              :title="$t('CONVERSATION_PARTICIPANTS.SIDEBAR_TITLE')"
              :is-open="isContactSidebarItemOpen('is_conv_participants_open')"
              @click="
                value =>
                  toggleSidebarUIState('is_conv_participants_open', value)
              "
            >
              <conversation-participant
                :conversation-id="conversationId"
                :inbox-id="inboxId"
              />
            </accordion-item>
          </div>
          <div v-else-if="element.name === 'conversation_info'">
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_INFO')"
              :is-open="isContactSidebarItemOpen('is_conv_details_open')"
              compact
              @click="
                value => toggleSidebarUIState('is_conv_details_open', value)
              "
            >
              <conversation-info
                :conversation-attributes="conversationAdditionalAttributes"
                :contact-attributes="contactAdditionalAttributes"
              />
            </accordion-item>
          </div>
          <div v-else-if="element.name === 'contact_attributes'">
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES')"
              :is-open="isContactSidebarItemOpen('is_contact_attributes_open')"
              compact
              @click="
                value =>
                  toggleSidebarUIState('is_contact_attributes_open', value)
              "
            >
              <custom-attributes
                attribute-type="contact_attribute"
                attribute-class="conversation--attribute"
                class="even"
                :contact-id="contact.id"
              />
              <custom-attribute-selector
                attribute-type="contact_attribute"
                :contact-id="contact.id"
              />
            </accordion-item>
          </div>
          <div v-else-if="element.name === 'previous_conversation'">
            <accordion-item
              v-if="contact.id"
              :title="
                $t('CONVERSATION_SIDEBAR.ACCORDION.PREVIOUS_CONVERSATION')
              "
              :is-open="isContactSidebarItemOpen('is_previous_conv_open')"
              @click="
                value => toggleSidebarUIState('is_previous_conv_open', value)
              "
            >
              <contact-conversations
                :contact-id="contact.id"
                :conversation-id="conversationId"
              />
            </accordion-item>
          </div>
          <woot-feature-toggle
            v-else-if="element.name === 'macros'"
            feature-key="macros"
          >
            <accordion-item
              :title="$t('CONVERSATION_SIDEBAR.ACCORDION.MACROS')"
              :is-open="isContactSidebarItemOpen('is_macro_open')"
              compact
              @click="value => toggleSidebarUIState('is_macro_open', value)"
            >
              <macros-list :conversation-id="conversationId" />
            </accordion-item>
          </woot-feature-toggle>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import AccordionItem from 'dashboard/components/Accordion/AccordionItem.vue';
import ContactConversations from './ContactConversations.vue';
import ConversationAction from './ConversationAction.vue';
import ConversationParticipant from './ConversationParticipant.vue';
import ContactDetailsItem from './ContactDetailsItem.vue';

import ContactInfo from './contact/ContactInfo.vue';
import ConversationInfo from './ConversationInfo.vue';

import CustomAttributes from './customAttributes/CustomAttributes.vue';
import CustomAttributeSelector from './customAttributes/CustomAttributeSelector.vue';
import draggable from 'vuedraggable';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import MacrosList from './Macros/List.vue';

export default {
  components: {
    AccordionItem,
    ContactConversations,
    ContactInfo,
    ConversationInfo,
    CustomAttributes,
    CustomAttributeSelector,
    ConversationAction,
    ConversationParticipant,
    draggable,
    MacrosList,
    ContactDetailsItem
  },
  mixins: [alertMixin, uiSettingsMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
    onToggle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dragEnabled: true,
      conversationSidebarItems: [],
      dragging: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      uiFlags: 'inboxAssignableAgents/getUIFlags',
    }),
    conversationAdditionalAttributes() {
      return this.currentConversationMetaData.additional_attributes || {};
    },
    channelType() {
      return this.currentChat.meta?.channel;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contactAdditionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    currentConversationMetaData() {
      return this.$store.getters[
        'conversationMetadata/getConversationMetadata'
      ](this.conversationId);
    },
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId && newConversationId !== prevConversationId) {
        this.getContactDetails();
      }
    },
    contactId() {
      this.getContactDetails();
    },
  },
  mounted() {
    this.conversationSidebarItems = this.conversationSidebarItemsOrder;
    this.getContactDetails();
    this.$store.dispatch('attributes/get', 0);
  },
  methods: {
    onPanelToggle() {
      this.onToggle();
    },
    getContactDetails() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    getAttributesByModel() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    openTranscriptModal() {
      this.showTranscriptModal = true;
    },

    onDragEnd() {
      this.dragging = false;
      this.updateUISettings({
        conversation_sidebar_items_order: this.conversationSidebarItems,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .contact--profile {
    @apply pb-3 border-b border-solid border-slate-75 dark:border-slate-700;
  }
    .custom-cart--row{
        margin-bottom:10px;
        font-size:12px;
        td{
            font-size:12px;
        }
        .product-summary-right{
            max-width: calc(100% - 40px);
        }
    }
  .product-container{
      display:flex;
      border-top:1px solid #ccc;
      border-left:1px solid #ccc;
      border-right:1px solid #ccc;
      padding:3px;
      font-size:12px;
      &:last-child {
          border-bottom:1px solid #ccc;
      }
      .product-img{
          width:40px;
      }
      .product-name{
          margin-bottom:1px;
          padding-left:5px;
          font-weight: bold;
      }
      .product-sku{
          margin-bottom:1px;
          padding-left:5px;
      }
      .product-price{
          margin-bottom:1px;
          padding-left:5px;
          .product-price-span{
              display: inline-block;
              width: 83px;
          }
      }
      .product-cat{
          padding-left:5px;
      }
  }
  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      @apply box-border pl-6;
    }
    .multiselect__element {
      span {
        @apply w-full;
      }
    }
  }
}
</style>
