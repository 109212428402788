import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import knowledgeklistAPI from '../../api/knowledgesklist';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getKnowledges($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }, agentId,pageid) => {
    commit(types.default.SET_KNOWLEDGEKLIST_FETCHING_STATUS, true);
    try {
      const response = await knowledgeklistAPI.show(agentId,pageid);
      commit(types.default.SET_KNOWLEDGEKLIST_FETCHING_STATUS, false);
      commit(types.default.SET_KNOWLEDGEKLISTS, response.data);
    } catch (error) {
      commit(types.default.SET_KNOWLEDGEKLIST_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_KNOWLEDGEKLIST_CREATING_STATUS, true);
    try {

      const response = await knowledgeklistAPI.create(agentInfo);
      commit(types.default.ADD_KNOWLEDGEKLIST, response.data);
      commit(types.default.SET_KNOWLEDGEKLIST_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_KNOWLEDGEKLIST_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_KNOWLEDGEKLIST_UPDATING_STATUS, true);
    try {
      const response = await knowledgeklistAPI.update(id, agentParams);
      commit(types.default.EDIT_KNOWLEDGEKLIST, response.data);
      commit(types.default.SET_KNOWLEDGEKLIST_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_KNOWLEDGEKLIST_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  updateSingleAgentPresence: ({ commit }, { id, availabilityStatus }) => {
    commit(types.default.UPDATE_SINGLE_KNOWLEDGEKLIST_PRESENCE, {
      id,
      availabilityStatus,
    });
  },
  updatePresence: async ({ commit }, data) => {
    commit(types.default.UPDATE_KNOWLEDGEKLISTS_PRESENCE, data);
  },
  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_KNOWLEDGEKLIST_DELETING_STATUS, true);
    try {
      await knowledgeklistAPI.delete(agentId);
      commit(types.default.DELETE_KNOWLEDGEKLIST, agentId);
      commit(types.default.SET_KNOWLEDGEKLIST_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_KNOWLEDGEKLIST_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_KNOWLEDGEKLIST_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_KNOWLEDGEKLIST_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_KNOWLEDGEKLIST_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_KNOWLEDGEKLIST_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_KNOWLEDGEKLISTS]: MutationHelpers.set,
  [types.default.ADD_KNOWLEDGEKLIST]: MutationHelpers.create,
  [types.default.EDIT_KNOWLEDGEKLIST]: MutationHelpers.update,
  [types.default.DELETE_KNOWLEDGEKLIST]: MutationHelpers.destroy,
  [types.default.UPDATE_KNOWLEDGEKLISTS_PRESENCE]: MutationHelpers.updatePresence,
  [types.default.UPDATE_SINGLE_KNOWLEDGEKLIST_PRESENCE]: (
    $state,
    { id, availabilityStatus }
  ) =>
    MutationHelpers.updateSingleRecordPresence($state.records, {
      id,
      availabilityStatus,
    }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
