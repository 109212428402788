import { createConsumer } from '@rails/actioncable';
import { BUS_EVENTS } from 'shared/constants/busEvents';

const PRESENCE_INTERVAL = 20000;
const RECONNECT_INTERVAL = 1000;

class BaseActionCableConnector {
  static isDisconnected = false;

  constructor(app, pubsubToken, websocketHost = '') {
    const websocketURL = websocketHost ? `${websocketHost}/cable` : undefined;

    this.consumer = createConsumer(websocketURL);
    this.subscription = this.consumer.subscriptions.create(
      {
        channel: 'RoomChannel',
        pubsub_token: pubsubToken,
        account_id: app.$store.getters.getCurrentAccountId,
        user_id: app.$store.getters.getCurrentUserID,
      },
      {
        updatePresence() {
          this.perform('update_presence');
        },
        received: this.onReceived,
        disconnected: () => {
          BaseActionCableConnector.isDisconnected = true;
          this.onDisconnected();
          this.initReconnectTimer();
          // TODO: Remove this after completing the conversation list refetching
          window.bus.$emit(BUS_EVENTS.WEBSOCKET_DISCONNECT);
        },
      }
    );
    this.app = app;
    this.events = {};
    this.reconnectTimer = null;
    this.isAValidEvent = () => true;
    this.triggerPresenceInterval = () => {
      setTimeout(() => {
        this.subscription.updatePresence();
        this.triggerPresenceInterval();
      }, PRESENCE_INTERVAL);
    };
    this.triggerPresenceInterval();
  }

  checkConnection() {
    const isConnectionActive = this.consumer.connection.isOpen();
    const isReconnected =
      BaseActionCableConnector.isDisconnected && isConnectionActive;
    if (isReconnected) {
      this.clearReconnectTimer();
      this.onReconnect();
      BaseActionCableConnector.isDisconnected = false;
    } else {
      this.initReconnectTimer();
    }
  }

  clearReconnectTimer = () => {
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }
  };

  initReconnectTimer = () => {
    this.clearReconnectTimer();
    this.reconnectTimer = setTimeout(() => {
      this.checkConnection();
    }, RECONNECT_INTERVAL);
  };

  // eslint-disable-next-line class-methods-use-this
  onReconnect = () => {};

  // eslint-disable-next-line class-methods-use-this
  onDisconnected = () => {};

  disconnect() {
    this.consumer.disconnect();
  }

  onReceived = ({ event, data } = {}) => {
    if (this.isAValidEvent(data)) {
     
      if (this.events[event] && typeof this.events[event] === 'function') {
        this.events[event](data);
        if(event=="message.updated"){
           var div = document.querySelector('.conversation--container');if(div) div.scrollTop = div.scrollHeight;
        }
      }else if(event=="disconnect"){
          let svg = '<svg xmlns="http://www.w3.org/2000/svg" style="width:50px;margin: 0 auto;fill:rgb(0, 156, 224)" data-name="Layer 1" viewBox="0 0 24 24">\n' +
          '  <path d="m17.084,15.67l2.551-3.847c.502-.757.548-1.725.119-2.525-.43-.801-1.261-1.298-2.17-1.298h-3.801L15.806,0h-3.321l-4.415,6.656L1.457.043.043,1.457l22.5,22.5,1.414-1.414-6.873-6.873Zm.5-5.67c.241,0,.358.152.407.244.049.091.111.273-.022.474l-2.327,3.509-4.227-4.227h6.169Zm-4.544-7.218l-1.785,7.058-1.741-1.741,3.526-5.317Zm.346,14.845l1.443,1.443-3.27,4.929h-3.321l2.023-8h-3.774c-.917,0-1.757-.503-2.191-1.313-.428-.798-.385-1.76.113-2.51l1.406-2.12,1.443,1.443-1.182,1.782c-.127.191-.065.369-.018.459.042.077.167.259.429.259h3.271l2.452,2.452-1.205,4.766,2.381-3.59Z"/>\n' +
          '</svg>';
          var newDiv = document.createElement("div");
          newDiv.setAttribute("id", "discontnet-notice");
          newDiv.setAttribute("style", "position:absolute;top:0;left:0;width:100%;background: rgba(255,255,255,0.9);height:100%;z-index:222");

          newDiv.innerHTML = "<div class='notice-text' style='position: relative;top: 50%;transform: translateY(-100%);text-align: center;color:rgb(0, 156, 224);padding: 0 10%;font-size:18px;'>" +svg+"<br>"+ data + "</div>";

          var originalDiv = document.querySelector(".chat-widget-container");
          if(originalDiv){
            originalDiv.appendChild(newDiv);
          }else {
            setTimeout(function(){
              var originalDiv = document.querySelector(".chat-widget-container");
              if(originalDiv) {
                originalDiv.appendChild(newDiv);
              }
            },3000);
          }
          console.log(originalDiv);


        this.disconnect();

        console.log("-------------------------disconnect");
      }
    }
  };
}

export default BaseActionCableConnector;
