var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings--content"},[_c('div',{staticClass:"widget-builder-container"},[_c('div',{staticClass:"settings-container w-100 lg:w-[40%]"},[_c('div',{staticClass:"settings-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateWidget.apply(null, arguments)}}},[_c('woot-avatar-uploader',{attrs:{"label":_vm.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.AVATAR.LABEL'),"src":_vm.avatarUrl,"delete-avatar":""},on:{"change":_vm.handleImageUpload,"onAvatarDelete":_vm.handleAvatarDelete}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.websiteName.$error },attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.PLACE_HOLDER'
              ),"error":_vm.websiteNameValidationErrorMsg},on:{"blur":_vm.$v.websiteName.$touch},model:{value:(_vm.websiteName),callback:function ($$v) {_vm.websiteName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"websiteName"}}),_vm._v(" "),_c('woot-input',{attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_HEADING.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_HEADING.PLACE_HOLDER'
              )},model:{value:(_vm.welcomeHeading),callback:function ($$v) {_vm.welcomeHeading=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"welcomeHeading"}}),_vm._v(" "),_c('woot-input',{attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_TAGLINE.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_TAGLINE.PLACE_HOLDER'
              )},model:{value:(_vm.welcomeTagline),callback:function ($$v) {_vm.welcomeTagline=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"welcomeTagline"}}),_vm._v(" "),_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.REPLY_TIME.LABEL'))+"\n            "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.replyTime),expression:"replyTime"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.replyTime=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.getReplyTimeOptions),function(option){return _c('option',{key:option.key,domProps:{"value":option.value}},[_vm._v("\n                "+_vm._s(option.text)+"\n              ")])}),0)]),_vm._v(" "),_c('label',[_vm._v("\n            "+_vm._s(_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_COLOR_LABEL'
              ))+"\n            "),_c('woot-color-picker',{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_vm._v(" "),_c('input-radio-group',{attrs:{"name":"widget-bubble-position","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION_LABEL'
              ),"items":_vm.widgetBubblePositions,"action":_vm.handleWidgetBubblePositionChange}}),_vm._v(" "),_c('input-radio-group',{attrs:{"name":"widget-bubble-type","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE_LABEL'
              ),"items":_vm.widgetBubbleTypes,"action":_vm.handleWidgetBubbleTypeChange}}),_vm._v(" "),_c('woot-input',{attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.PLACE_HOLDER'
              )},model:{value:(_vm.widgetBubbleLauncherTitle),callback:function ($$v) {_vm.widgetBubbleLauncherTitle=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"widgetBubbleLauncherTitle"}}),_vm._v(" "),_c('input-radio-group',{attrs:{"name":"widget-bubble-offlineinfo-hide","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.OFFLINE_HIDE'
              ),"items":_vm.widgetOfflineTypes,"action":_vm.handlewidgetOfflineTypeChange}}),_vm._v(" "),_c('woot-submit-button',{staticClass:"submit-button",attrs:{"button-text":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.UPDATE.BUTTON_TEXT'
              ),"loading":_vm.uiFlags.isUpdating,"disabled":_vm.$v.$invalid || _vm.uiFlags.isUpdating}})],1)])]),_vm._v(" "),_c('div',{staticClass:"widget-container w-100 lg:w-[60%]"},[_c('input-radio-group',{style:({ 'text-align': 'center' }),attrs:{"name":"widget-view-options","items":_vm.getWidgetViewOptions,"action":_vm.handleWidgetViewChange}}),_vm._v(" "),(_vm.isWidgetPreview)?_c('div',{staticClass:"widget-preview"},[_c('Widget',{attrs:{"welcome-heading":_vm.welcomeHeading,"welcome-tagline":_vm.welcomeTagline,"website-name":_vm.websiteName,"logo":_vm.avatarUrl,"is-online":"","reply-time":_vm.replyTime,"color":_vm.color,"widget-bubble-position":_vm.widgetBubblePosition,"widget-bubble-launcher-title":_vm.widgetBubbleLauncherTitle,"widget-bubble-type":_vm.widgetBubbleType,"widget-offline-type":_vm.widgetOfflineType}})],1):_c('div',{staticClass:"widget-script"},[_c('woot-code',{attrs:{"script":_vm.widgetScript}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }