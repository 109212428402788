<template>
  <div class="flex-1 overflow-auto p-4">
        <iframe class="w-full gtp-iframe" style="height:100%" :src="iframe_url"></iframe>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';



export default {
  components: {

  },
  mixins: [globalConfigMixin],
  data() {
    return {
          loading: {},
          iframe_url:"",
          knowledgeAPI: {
          message: '',
      },
      current: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),

  },
  mounted() {
      let kids = this.$route.params.kid.split("|");
      this.iframe_url= "https://gpt.meiyan.chat/kb/detail?kbId="+kids[1]+"&ukey="+kids[0];
      console.log(this.iframe_url);


  },
  watch: {
    $route(to) {
        if (to.name === 'knowledge_klist') {
            let kids = this.$route.params.kid.split("|");
            this.iframe_url= "https://gpt.meiyan.chat/kb/detail?kbId="+kids[1]+"&ukey="+kids[0];
            console.log(this.iframe_url);
        }
    },
  },
  methods: {

    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected knowledge
      this.loading[this.current.id] = false;
      this.current = {};
      // Show message
      this.knowledgeAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>
