<template>
    <div class="flex flex-row gap-4">
      <div  v-if="currentUser.role!='administrator'"  :style="{ position: 'absolute',top:'30%',left:'40%' }"  class="kb-title-info">
            你还没有权限管理知识库，请联系管理员！
      </div>

      <div  v-else-if="kb_links.length>0" :style="{ position: 'absolute',top:'70px',left:'17.2%' }" >

            <div   v-for="(item, i) in kb_links"    :key="i">
                <div class="kb-title-info">知识库站点：<a class="kb-title-info" target="_blank" :href="item.url">{{item.host}}</a></div>

            </div>
        </div>
        <div class="kb-title-info" v-else  :style="{ position: 'absolute',top:'30%',left:'40%' }">
            你还没有配置知识库，请联系管理员！
        </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';



export default {
  components: {

  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      kb_link:"",
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      knowledgeAPI: {
        message: '',
      },

      currentAgent: {},
    };
  },
  computed: {
    ...mapGetters({

      currentUser: 'getCurrentUser',

    }),
    kb_links(){
          let kb_links = [];
          if(this.currentUser.role=="administrator"){
              if(this.currentUser.custom_attributes ){
                  const urls = Object.values(this.currentUser.custom_attributes.urls);
                  if( urls.length>0 ){
                      for(let i=0;i<urls.length;i++){
                          let url = new URL(urls[i]);
                          let host = url.host;
                          kb_links[i] = {"url":urls[i]+"?key="+this.currentUser.access_token,"host":host};
                      }
                  }else if( this.currentUser.custom_attributes.urls.qfyurl ){
                      const url = new URL(this.currentUser.custom_attributes.qfyurl);
                      const host = url.host;
                      kb_links[0] = {"url":this.currentUser.custom_attributes.qfyurl+"?key="+this.currentUser.access_token,"host":host};
                  }
              }
          }
          return kb_links;
      }
  },
  mounted() {



  },
  methods: {

  },
};
</script>
<style >
.kb-title-info{font-size:24px;}
</style>
