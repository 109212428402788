<template>
  <div v-if="availableProfiles.length" class="flex items-end mx-0 my-2 gap-3">
    <a
      v-for="profile in availableProfiles"
      :key="profile.key"
      :href="`${profile.link}${socialProfiles[profile.key]}`"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >

        <img
                v-if="profile.key=='qifeiye'"
                src="~dashboard/assets/images/qfy_logo.png"
                alt="logo"
        />
        <span
                v-else-if="profile.key=='website_url'"
                class="icon-button flex items-center justify-center ml-1"

        >
            前往网站查看联系人订单 <svg width="12" height="12" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon icon--font open"><path d="M6.25 4.5A1.75 1.75 0 0 0 4.5 6.25v11.5c0 .966.783 1.75 1.75 1.75h11.5a1.75 1.75 0 0 0 1.75-1.75v-4a.75.75 0 0 1 1.5 0v4A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V6.25A3.25 3.25 0 0 1 6.25 3h4a.75.75 0 0 1 0 1.5h-4ZM13 3.75a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0V5.56l-5.22 5.22a.75.75 0 0 1-1.06-1.06l5.22-5.22h-4.69a.75.75 0 0 1-.75-.75Z" fill="currentColor"></path></svg>
        </span>
        <fluent-icon
                v-else
                :icon="`brand-${profile.key}`"
                size="32"
                class="text-slate-500 dark:text-slate-400 hover:text-slate-700 dark:hover:text-slate-200"
        />


    </a>



  </div>
</template>

<script>
export default {
  props: {
    socialProfiles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      socialMediaLinks: [
        { key: 'facebook', icon: 'facebook', link: 'https://facebook.com/' },
        { key: 'twitter', icon: 'twitter', link: 'https://twitter.com/' },
        { key: 'linkedin', icon: 'linkedin', link: 'https://linkedin.com/' },
        { key: 'github', icon: 'github', link: 'https://github.com/' },
        { key: 'instagram', icon: 'instagram', link: 'https://instagram.com/' },
        { key: 'qifeiye', icon: 'instagram', link: 'https://www.qifeiye.com/api/servicer.php?token=qifeiye&type=web&open_id=' },
        { key: 'website_url', icon: 'instagram', link: '' },
      ],
    };
  },
  computed: {
    availableProfiles() {
      return this.socialMediaLinks.filter(
        mediaLink => !!this.socialProfiles[mediaLink.key]
      );
    },
  },
};
</script>
